<template>
    <a-modal :title="isEdit ? '编辑水厂' : '添加水厂'" :width="640" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入名称" v-decorator="['name', { rules: [{required: true, min: 2, message: '名称最少2个字符'}]}]" />
                </a-form-item>
                <a-form-item label="所属省" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select
                            @change="handleProvinceChange"
                            placeholder="请选择所属省"
                            :disabled="level >= 1"
                            v-decorator="['provinceId', { rules: [{required: false, message: '请选择所属省'}]}]"
                    >
                        <a-select-option v-for="item in provinces" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="所属市" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select
                            :allowClear="true"
                            :disabled="level >= 2"
                            @change="handleCityChange"
                            placeholder="请选择所属市"
                            v-decorator="['cityId', { rules: [{required: false, message: '请选择所属市'}]}]"
                    >
                        <a-select-option v-for="item in cites" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="所属区" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select
                            :allowClear="true"
                            :disabled="level >= 3"
                            placeholder="请选择所属区"
                            v-decorator="['areaId', { rules: [{required: false, message: '请选择所属区'}]}]"
                    >
                        <a-select-option v-for="item in areas" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="经度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入经度" v-decorator="['lon', { rules: [{required: true, message: '请输入经度'}]}]" />
                </a-form-item>
                <a-form-item label="纬度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入纬度" v-decorator="['lat', { rules: [{required: true, message: '请输入纬度'}]}]" />
                </a-form-item>
                <a-form-item label="总水表ID" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;" placeholder="请输入总水表ID" v-decorator="['maindeviceId', { rules: [{required: false, message: '请输入总水表ID'}]}]" />
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script>
  import { getAreaParam,addWaterfactory, updateWaterfactory,findRegionById,findRegionByPid } from "@/api/monitor/index"
  import moment from 'moment'
  import pick from 'lodash.pick'
  export default {
    data() {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        },
        visible: false,
        confirmLoading: false,
        isEdit: false,
        id: null,
        record: null,
        form: this.$form.createForm(this),
        provinces:[],
        cites:[],
        areas:[],
        level: 0

      }
    },
    created() {
        console.log('WaterfactoryModal created....')

    },
    mounted(){
      console.log('WaterfactoryModal mounted ----------')
    },
    methods: {
      add() {
        this.visible = true
        this.isEdit = false
        const {provinceId,cityId,areaId,level} = getAreaParam()
        this.level = level
        console.log(provinceId,cityId,areaId,level)
        if(provinceId > 0){
          findRegionById({id:provinceId}).then(r=>{
            this.provinces.splice(0)
            this.provinces.push(r.data)
          })
          findRegionByPid({pid:provinceId}).then(r=>{
            this.cites.splice(0)
            this.cites = r.data
          })
          findRegionByPid({pid:cityId}).then(r=>{
            this.areas.splice(0)
            this.areas = r.data
          })
          this.form.resetFields()
          const { form: { setFieldsValue } } = this
          this.$nextTick(() => {
            setFieldsValue({provinceId: provinceId,cityId: cityId,areaId:areaId})
          })
        }
      },
      edit(record) {
        this.id = record.id
        this.record = record
        this.visible = true
        this.isEdit = true
        console.log('edit');
        console.log(this.record);
        const {provinceId,cityId,areaId,level} = getAreaParam()
        this.level = level
        console.log(provinceId,cityId,areaId,level)
        if(record.provinceId > 0){
          findRegionById({id:record.provinceId}).then(r=>{
            this.provinces.splice(0)
            this.provinces.push(r.data)
          })
          findRegionByPid({pid:record.provinceId}).then(r=>{
            this.cites.splice(0)
            this.cites = r.data
          })
          findRegionByPid({pid:record.cityId}).then(r=>{
            this.areas.splice(0)
            this.areas = r.data
          })
        }
        const { form: { setFieldsValue } } = this
        this.form.resetFields()
        this.$nextTick(() => {
          setFieldsValue(pick(record, ['name', 'lon', 'lat','provinceId','cityId','areaId','maindeviceId']))
        })
      },
      handleProvinceChange(value){

      },
      handleCityChange(value){

      },
      handleSubmit() {
        const { form: { validateFields } } = this
        this.confirmLoading = true
        validateFields((errors, values) => {
          if (!errors) {
            if (this.isEdit) {
              const param = Object.assign({id:this.id},values)
              updateWaterfactory(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.$emit('ok', values)
                  this.visible = false
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            } else {
              const param = Object.assign({},values)
              addWaterfactory(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.visible = false
                  this.$emit('ok', values)
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>
