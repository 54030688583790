<template>
    <a-row :gutter="16" style="margin: 10px 20px;">
        <a-col :md="6">
            <a-card size="small" title="水厂" class="list-card" :body-style="{padding: 0}">
                <a slot="extra" @click="showWaterfactoryModal(1)">添加</a>
                <div v-if="waterfactorys.length > 0" v-for="(item,index) in waterfactorys" style="">
                   <div @click="handleWaterfactoryClick(item,index)" class="list-item-wrap" :class="waterfactoryIndex === index ? 'list-item-selected' : ''">
                       <div>
                           {{item.name}}
                       </div>
                       <div v-if="waterfactoryIndex === index" class="list-item-selected-icon">
                           <a-icon type="edit" @click="showWaterfactoryModal(2)"/>
                           <a-icon type="delete" @click="deleteWaterfactory(item)" style="margin-left: 10px;"/>
                       </div>
                   </div>
                    <div v-if="waterfactoryIndex === index" class="list-item-detail-wrap">
                       <span>经纬度:{{item.lon}},{{item.lat}}</span>
                    </div>
                </div>
                <div v-if="waterfactorys.length == 0" class="list-empty">
                   <a-empty></a-empty>
                </div>
            </a-card>
        </a-col>
        <a-col :md="6">
            <a-card size="small" title="视频点" class="list-card" :body-style="{padding: 0}">
                <a slot="extra" @click="showMonitorModal(1)">添加</a>
                <div v-if="monitors.length > 0" v-for="(item,index) in monitors" style="">
                    <div @click="handleMonitorClick(item,index)" class="list-item-wrap" :class="monitorIndex === index ? 'list-item-selected' : ''">
                        <div>
                            {{item.name}}
                        </div>
                        <div v-if="monitorIndex === index" class="list-item-selected-icon">
                            <a-icon type="edit" @click="showMonitorModal(2)"/>
                            <a-icon type="delete" @click="deleteMonitor(item)" style="margin-left: 10px;"/>
                        </div>
                    </div>
                    <div v-if="monitorIndex === index" class="list-item-detail-wrap">
                        <span>经纬度:{{item.lon}},{{item.lat}}</span>
                    </div>
                </div>
                <div v-if="monitors.length == 0" class="list-empty">
                    <a-empty></a-empty>
                </div>
            </a-card>
        </a-col>
        <a-col :md="6">
            <a-card size="small" title="摄像头" class="list-card" :body-style="{padding: 0}">
                <a slot="extra" @click="showGroupModal(1)">添加</a>
                <div v-if="groups.length > 0" v-for="(item,index) in groups" style="">
                    <div @click="handleGroupClick(item,index)" class="list-item-wrap" :class="groupIndex === index ? 'list-item-selected' : ''">
                        <div>
                            {{item.name}}
                        </div>
                        <div v-if="groupIndex === index" class="list-item-selected-icon">
                            <a-icon type="edit" @click="showGroupModal(2)"/>
                            <a-icon type="delete" @click="deleteGroup(item)" style="margin-left: 10px;"/>
                        </div>
                    </div>
                    <div v-if="groupIndex === index" class="list-item-detail-wrap">
                        <div>品牌：{{item.brand}}</div>
                        <div>型号：{{item.model}}</div>
                    </div>
                </div>
                <div v-if="groups.length == 0" class="list-empty">
                    <a-empty></a-empty>
                </div>
            </a-card>
        </a-col>
        <!--<a-col :md="9">-->
            <!--<a-card size="small" title="设备列表" class="list-card" :body-style="{padding: 0}">-->
                <!--<a slot="extra" href="#">添加</a>-->
            <!--</a-card>-->
        <!--</a-col>-->
        <waterfactory-modal ref="waterfacoryModal" @ok="handleWaterfactoryModalOk"></waterfactory-modal>
        <video-group-modal ref="monitorModal" @ok="handleMonitorModalOk"></video-group-modal>
        <video-modal ref="groupModal" @ok="handleGroupModalOk"></video-modal>
    </a-row>
</template>

<script>
  import { findGroupVideoByWfid,findVideoByGroupid,deleteWaterfactoryt,deleteVideoGroup,deleteVideo,getRegion,findRegionById,findRegionByPid,getAreaParam,devicePage,deviceExport,deviceTypeAll,zhibiaoAll,monitorAll,monitorList,groupList,waterfactoryAll,findWaterfactoryByRegion } from "@/api/monitor/index";
  import '../monitor_pub.less'
  import moment from 'moment'
  import WaterfactoryModal from '@/views/monitor/component/WaterfactoryModal'
  import VideoGroupModal from '@/views/monitor/component/VideoGroupModal'
  import VideoModal from '@/views/monitor/component/VideoModal'
  export default {
    name: "MonitorPane",
    components:{
      WaterfactoryModal,
      VideoGroupModal,
      VideoModal
    },
    data(){
      return{
        waterfactorys: [],
        waterfactoryIndex: -1,
        monitors: [],
        monitorIndex: -1,
        groups:[],
        groupIndex: -1,
        devices:[],
        deviceIndex:-1
      }
    },
    created(){
     this.fetchWaterfactoryList()
    },
    methods:{
      fetchWaterfactoryList(){
        let param = getAreaParam()
        findWaterfactoryByRegion(param).then(r=>{
          const list = r.data
          this.waterfactorys.splice(0)
          for (let i = 0; i < list.length; i++) {
            list[i].selected = false
            this.waterfactorys.push(list[i])
          }
        })
      },
      handleWaterfactoryClick(item,index){
        console.log("item",item)
        if(this.waterfactoryIndex === index){
          return
        }
        for (let i = 0; i < this.waterfactorys.length; i++) {
          this.waterfactorys[i].selected = false
        }
        item.selected = true
        this.waterfactoryIndex = index
        this.fetchMonitorList()
        this.monitorIndex = -1
        this.groupIndex = -1
        this.groups.splice(0)
      },
      showWaterfactoryModal(type){
        if(type === 1){
          this.$refs.waterfacoryModal.add()
        }else{
          this.$refs.waterfacoryModal.edit(this.waterfactorys[this.waterfactoryIndex])
        }
      },
      handleWaterfactoryModalOk(values){
        this.fetchWaterfactoryList()
      },
      deleteWaterfactory(item){
        const self = this
        this.$confirm({
          title: '确认删除',
          content: `是否确认删除当前水厂？`,
          cancelText: '取消',
          okText: '确定',
          okType: 'danger',
          onOk () {
            return new Promise((resolve, reject) => {
              deleteWaterfactoryt({ id: item.id }).then(r => {
                if(r.code === 0){
                  self.$message.info('删除成功')
                  self.fetchWaterfactoryList()
                }else{
                  self.$message.warn(r.msg)
                }
              }).catch((e) => {
              }).finally(() => {
                resolve()
              })
            })
          }
        })
      },
      fetchMonitorList(){
        findGroupVideoByWfid(this.waterfactorys[this.waterfactoryIndex].id ).then(r => {
          const list = r.data
          this.monitors.splice(0)
          for (let i = 0; i < list.length; i++) {
            list[i].selected = false
            this.monitors.push(list[i])
          }
        });
      },
      handleMonitorClick(item,index){
        console.log("item",item)
        for (let i = 0; i < this.monitors.length; i++) {
          this.monitors[i].selected = false
        }
        item.selected = true
        this.monitorIndex = index
        this.fetchGroupList()
      },
      showMonitorModal(type){
        if(this.waterfactoryIndex === -1){
          this.$message.info("请先选择一个水厂")
          return
        }
        if(type === 1){
          this.$refs.monitorModal.add(this.waterfactorys[this.waterfactoryIndex].id)
        }else{
          this.$refs.monitorModal.edit(this.monitors[this.monitorIndex])
        }
      },
      handleMonitorModalOk(values){
        this.fetchMonitorList()
      },
      deleteMonitor(item){
        const self = this
        this.$confirm({
          title: '确认删除',
          content: `是否确认删除当前视频点？`,
          cancelText: '取消',
          okText: '确定',
          okType: 'danger',
          onOk () {
            return new Promise((resolve, reject) => {
              deleteVideoGroup({ id: item.id }).then(r => {
                if(r.code === 0){
                  self.$message.info('删除成功')
                  self.fetchMonitorList()
                }else{
                  self.$message.warn(r.msg)
                }
              }).catch((e) => {
              }).finally(() => {
                resolve()
              })
            })
          }
        })
      },
      fetchGroupList(){
        findVideoByGroupid(this.monitors[this.monitorIndex].id).then(r => {
          const list = r.data
          this.groups.splice(0)
          for (let i = 0; i < list.length; i++) {
            list[i].selected = false
            this.groups.push(list[i])
          }
        });
      },
      handleGroupClick(item,index){
        console.log("item",item)
        for (let i = 0; i < this.groups.length; i++) {
          this.groups[i].selected = false
        }
        item.selected = true
        this.groupIndex = index
      },
      showGroupModal(type){
        if(this.monitorIndex === -1){
          this.$message.info("请先选择一个视频点")
          return
        }
        if(type === 1){
          this.$refs.groupModal.add(this.monitors[this.monitorIndex].id)
        }else{
          this.$refs.groupModal.edit(this.groups[this.groupIndex])
        }
      },
      handleGroupModalOk(values){
        this.fetchGroupList()
      },
      deleteGroup(item){
        const self = this
        this.$confirm({
          title: '确认删除',
          content: `是否确认删除当前摄像头？`,
          cancelText: '取消',
          okText: '确定',
          okType: 'danger',
          onOk () {
            return new Promise((resolve, reject) => {
              deleteVideo({ id: item.id }).then(r => {
                if(r.code === 0){
                  self.$message.info('删除成功')
                  self.fetchGroupList()
                }else{
                  self.$message.warn(r.msg)
                }
              }).catch((e) => {
              }).finally(() => {
                resolve()
              })
            })
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>
