<template>
    <a-modal :title="isEdit ? '编辑摄像头' : '添加摄像头'" :width="640" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入名称" v-decorator="['name', { rules: [{required: true, min: 2, message: '名称最少2个字符'}]}]" />
                </a-form-item>
                <a-form-item label="品牌" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入品牌" v-decorator="['brand', { rules: [{required: false, message: '请输入品牌'}]}]" />
                </a-form-item>
                <a-form-item label="型号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入型号" v-decorator="['model', { rules: [{required: false, message: '请输入型号'}]}]" />
                </a-form-item>
                <a-form-item label="SN" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入SN" v-decorator="['sn', { rules: [{required: false, message: '请输入SN'}]}]" />
                </a-form-item>
                <a-form-item label="直播地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-textarea :rows="5" placeholder="请输入直播地址" v-decorator="['param', { rules: [{required: true, message: '请输入直播地址'}]}]" />
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script>
  import { getAreaParam,addVideo, updateVideo } from "@/api/monitor/index"
  import moment from 'moment'
  import pick from 'lodash.pick'
  export default {
    data() {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        },
        visible: false,
        confirmLoading: false,
        isEdit: false,
        id: null,
        groupid: null,
        record: null,
        form: this.$form.createForm(this),
      }
    },
    created() {

    },
    methods: {
      add(groupid) {
        this.groupid = groupid
        this.visible = true
        this.isEdit = false
        this.form.resetFields()
      },
      edit(record) {
        this.groupid = record.groupid
        this.id = record.id
        this.record = record
        this.visible = true
        this.isEdit = true
        console.log('edit');
        console.log(this.record);
        const { form: { setFieldsValue } } = this
        this.form.resetFields()
        this.$nextTick(() => {
          setFieldsValue(pick(record, ['name','param','brand','model','sn']))
        })
      },
      handleSubmit() {
        const { form: { validateFields } } = this
        this.confirmLoading = true
        validateFields((errors, values) => {
          if (!errors) {
            if (this.isEdit) {
              const param = {id:this.id,groupid: this.groupid,name:values.name,param:values.param,brand:values.brand,model:values.model,sn:values.sn}
              updateVideo(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.$emit('ok', values)
                  this.visible = false
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            } else {
              const param = Object.assign({},{groupid:this.groupid},values)
              addVideo(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.visible = false
                  this.$emit('ok', values)
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>
