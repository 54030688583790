<template>
  <div style="background-color: white;">
    <a-tabs default-active-key="1" @change="handleTabChange">
      <a-tab-pane key="1" tab="设备类型">
        <a-card title="查询结果" :bordered="false" style="margin-top: 5px;">
          <div slot="extra">
            <a-button
              type="primary"
              icon="plus"
              style="margin-left:10px;"
              @click="showAddDeviceTypeModal"
            >
              添加设备类型
            </a-button>
          </div>
          <a-table
            row-key="index"
            :columns="columns"
            :dataSource="data"
            :pagination="false"
            :loading="confirmLoading"
          >
            <a slot="leakPercent" slot-scope="text, record">{{ text }}%</a>
            <template slot="index" slot-scope="text, record">
              <div>{{ text }}</div>
            </template>
            <template slot="time" slot-scope="text, record">
              <div>{{ record.start }} - {{ record.end }}</div>
            </template>
            <span slot="action" slot-scope="text, record">
              <template>
                <a-button
                  type="primary"
                  size="small"
                  @click="handleEdit(record)"
                >
                  <a-icon type="edit" theme="filled" />
                </a-button>
                <a-button
                        style="margin-left: 10px;"
                  type="danger"
                  size="small"
                  @click="handleDel(record)"
                >
                  <a-icon type="delete" theme="filled" />
                </a-button>
              </template>
            </span>
          </a-table>
        </a-card>
        <device-type-modal ref="deviceTypeModal" @ok="handleOk"></device-type-modal>
      </a-tab-pane>
      <a-tab-pane key="2" tab="监测点" force-render>
        <monitor-pane></monitor-pane>
      </a-tab-pane>
      <a-tab-pane key="3" tab="视频点">
        <video-pane></video-pane>
      </a-tab-pane>
      <a-tab-pane key="4" tab="组态点">
        <zutai-pane></zutai-pane>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
  import moment from "moment";
import {
  deviceTypeAllWithZhibiao,
  deleteDeviceType,
  zhibiaoAll
} from "@/api/monitor/index";
import DeviceTypeModal from "./component/DeviceTypeModal"
  import MonitorPane from "./component/MonitorPane"
  import VideoPane from "./component/VideoPane"
  import ZutaiPane from "./component/ZutaiPane"
export default {
  name: "set",
  components:{
    DeviceTypeModal,
    MonitorPane,
    VideoPane,
    ZutaiPane
  },
  data() {
    return {
      confirmLoading: false,
      columns: [
        {
          dataIndex: "index",
          title: "序号",
          align: "center",
          scopedSlots: { customRender: "index" }
        },
        {
          dataIndex: "name",
          title: "类型名称",
          align: "center"
        },
        {
          dataIndex: "zhibiaoNames",
          title: "对应指标",
          align: "center"
        },
        {
          dataIndex: "createTime",
          title: "添加时间",
          align: "center"
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 150,
          scopedSlots: { customRender: "action" },
          align: "center"
        }
      ],
      data: [],
      zhibiaoList: [],
      showAdd: false
    };
  },
  created(){
    zhibiaoAll().then(r=>{
      this.zhibiaoList = r.data
      this.fetchList()
    })

  },
  methods: {
    fetchList(){
      deviceTypeAllWithZhibiao().then(r=>{
        const list = r.data
        for (let i = 0; i < list.length; i++) {
          list[i].index = i + 1
          list[i].createTime = moment(list[i].createTime).format("YYYY-MM-DD HH:mm:ss");
          let zhibiaos = list[i].zhibiaos
          let arr = []
          for (let j = 0; j < zhibiaos.length; j++) {
            arr.push(this.getZhibiaoName(zhibiaos[j]))
          }
          list[i].zhibiaoNames = arr.join("，")
          for (let j = 0; j < zhibiaos.length; j++) {
            list[i].zhibiaos[j] = '' + list[i].zhibiaos[j]
          }
        }
        this.data = list
      })
    },
    getZhibiaoName(id){
      for (let i = 0,len = this.zhibiaoList.length; i < len; i++) {
        if(id === this.zhibiaoList[i].id){
          return this.zhibiaoList[i].name
        }
      }
      return ''
    },
    handleTabChange(e) {},
    showAddDeviceTypeModal() {
      this.$refs.deviceTypeModal.add(this.zhibiaoList)
    },
    handleOk(values){
      this.$message.info(values.isEdit ? '修改成功' : '添加成功')
      this.fetchList()
    },
    handleEdit(record){
      this.$refs.deviceTypeModal.edit(record,this.zhibiaoList)
    },
    handleDel(record){
      const self = this
      this.$confirm({
        title: '确认删除',
        content: `是否确认删除当前设备类型？`,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            deleteDeviceType({ id: record.id }).then(r => {
              if(r.code === 0){
                self.$message.info('删除成功')
                self.fetchList()
              }else{
                self.$message.warn(r.msg)
              }
            }).catch((e) => {
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    }
  }
};
</script>

<style scoped></style>
