<template>
    <a-modal :title="isEdit ? '编辑组态点' : '添加组态点'" :width="640" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入名称" v-decorator="['name', { rules: [{required: true, min: 2, message: '名称最少2个字符'}]}]" />
                </a-form-item>
                <a-form-item label="经度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;"  placeholder="请输入经度" v-decorator="['lon', { rules: [{required: true, message: '请输入经度'}]}]" />
                </a-form-item>
                <a-form-item label="纬度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input-number style="width: 100%;"  placeholder="请输入纬度" v-decorator="['lat', { rules: [{required: true, message: '请输入纬度'}]}]" />
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script>
  import { getAreaParam,addZutai, updateZutai } from "@/api/monitor/index"
  import moment from 'moment'
  import pick from 'lodash.pick'
  export default {
    data() {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        },
        visible: false,
        confirmLoading: false,
        isEdit: false,
        id: null,
        wfid: null,
        record: null,
        form: this.$form.createForm(this),
      }
    },
    created() {

    },
    methods: {
      add(wfid) {
        this.wfid = wfid
        this.visible = true
        this.isEdit = false
        this.form.resetFields()
      },
      edit(record) {
        this.id = record.id
        this.wfid = record.wfid
        this.record = record
        this.visible = true
        this.isEdit = true
        console.log('edit');
        console.log(this.record);
        const { form: { setFieldsValue } } = this
        this.form.resetFields()
        this.$nextTick(() => {
          setFieldsValue(pick(record, ['name', 'lon', 'lat']))
        })
      },
      handleSubmit() {
        const { form: { validateFields } } = this
        this.confirmLoading = true
        validateFields((errors, values) => {
          if (!errors) {
            if (this.isEdit) {
              const param = {id:this.id,wfid: this.wfid,name:values.name,lon:values.lon,lat:values.lat}
              updateZutai(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.$emit('ok', values)
                  this.visible = false
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            } else {
              const param = Object.assign({wfid: this.wfid},values)
              addZutai(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.visible = false
                  this.$emit('ok', values)
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>
