<template>
    <a-modal :title="isEdit ? '编辑管井' : '添加管井'" :width="640" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入名称" v-decorator="['name', { rules: [{required: true, min: 2, message: '名称最少2个字符'}]}]" />
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script>
  import { getAreaParam,addGroup, updateGroup } from "@/api/monitor/index"
  import moment from 'moment'
  import pick from 'lodash.pick'
  export default {
    data() {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        },
        visible: false,
        confirmLoading: false,
        isEdit: false,
        id: null,
        mid: null,
        record: null,
        form: this.$form.createForm(this),
      }
    },
    created() {

    },
    methods: {
      add(mid) {
        this.mid = mid
        this.visible = true
        this.isEdit = false
        this.form.resetFields()
      },
      edit(record) {
        this.mid = record.mid
        this.id = record.id
        this.record = record
        this.visible = true
        this.isEdit = true
        console.log('edit');
        console.log(this.record);
        const { form: { setFieldsValue } } = this
        this.form.resetFields()
        this.$nextTick(() => {
          setFieldsValue(pick(record, ['name']))
        })
      },
      handleSubmit() {
        const { form: { validateFields } } = this
        this.confirmLoading = true
        validateFields((errors, values) => {
          if (!errors) {
            if (this.isEdit) {
              const param = {id:this.id,mid: this.mid,name:values.name}
              updateGroup(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.$emit('ok', values)
                  this.visible = false
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            } else {
              const param = Object.assign({},{mid:this.mid},values)
              addGroup(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.visible = false
                  this.$emit('ok', values)
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>
