<template>
    <a-modal :title="isEdit ? '编辑组态组件' : '添加组态组件'" :width="640" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item label="监测点" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <!--<a-input placeholder="请输入监测点ID" v-decorator="['mid', { rules: [{required: true, message: '请输入监测点ID'}]}]" />-->
                    <a-select
                            style="width: 100%"
                            placeholder="请选择"
                            v-decorator="['mid', { rules: [{required: true, message: '请选择所属监测点'}]}]"
                            @change="handleMonitorChange"
                    >
                        <a-select-option
                                v-for="(item, index) in monitors"
                                :key="item.id"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="设备" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <!--<a-input placeholder="请输入idinmonitor" v-decorator="['idinmonitor', { rules: [{required: false, message: '请输入idinmonitor'}]}]" />-->
                    <a-select
                            style="width: 100%"
                            placeholder="请选择对应设备"
                            v-decorator="['idinmonitor', { rules: [{required: true, message: '请选择对应设备'}]}]"
                            @change="handleMonitorChange"
                    >
                        <a-select-option
                                v-for="(item, index) in devices"
                                :key="item.id"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="客户端标记" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入客户端标记" v-decorator="['flagid', { rules: [{required: true, message: '请输入客户端标记'}]}]" />
                </a-form-item>
                <a-form-item label="显示内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入显示内容" v-decorator="['descr', { rules: [{required: true, message: '请输入显示内容'}]}]" />
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script>
  import { getAreaParam,addZutaiComponent, updateZutaiComponent ,findDevicesByMid} from "@/api/monitor/index"
  import moment from 'moment'
  import pick from 'lodash.pick'
  export default {
    data() {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        },
        visible: false,
        confirmLoading: false,
        isEdit: false,
        id: null,
        ztid: null,
        record: null,
        form: this.$form.createForm(this),
        monitors: [],
        devices: []
      }
    },
    created() {

    },
    methods: {
      add(ztid,monitors) {
        this.ztid = ztid
        this.monitors = monitors
        this.visible = true
        this.isEdit = false
        this.form.resetFields()
      },
      edit(record,monitors) {
        this.monitors = monitors
        this.ztid = record.ztid
        this.id = record.id
        this.record = record
        this.visible = true
        this.isEdit = true
        console.log('edit');
        console.log(this.record);
        const { form: { setFieldsValue } } = this
        this.form.resetFields()
        this.$nextTick(() => {
          setFieldsValue(pick(record, ['mid','idinmonitor','flagid','descr']))
        })
        findDevicesByMid({mid:record.mid}).then(r=>{
          this.devices.splice(0)
          const list = r.data
          for (let i = 0; i < list.length; i++) {
            this.devices.push(list[i])
          }
          this.devices.unshift({name:'无',idinmonitor: -1,id:-1})
        })
      },
      handleMonitorChange(value){
        console.log('handleMonitorChange',value)
        if(value == -1){
          this.devices.splice(0)
          this.devices.unshift({name:'无',idinmonitor: -1,id:-1})
          this.form.setFieldsValue({idinmonitor:-1})
        }else{
          findDevicesByMid({mid:value}).then(r=>{
            this.devices.splice(0)
            const list = r.data
            for (let i = 0; i < list.length; i++) {
              this.devices.push(list[i])
            }
            this.devices.unshift({name:'无',idinmonitor: -1,id:-1})
          })
        }

      },
      handleSubmit() {
        const { form: { validateFields } } = this
        this.confirmLoading = true
        validateFields((errors, values) => {
          if (!errors) {
            if (this.isEdit) {
              const param = {id:this.id,ztid: this.ztid,mid:values.mid,idinmonitor:values.idinmonitor,flagid:values.flagid,descr:values.descr}
              updateZutaiComponent(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.$emit('ok', values)
                  this.visible = false
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            } else {
              const param = Object.assign({},{ztid:this.ztid},values)
              addZutaiComponent(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.visible = false
                  this.$emit('ok', values)
                }else{
                  this.$message.warn(r.msg)
                }
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>
