<template>
    <a-row :gutter="16" style="margin: 10px 20px;">
        <a-col :md="6">
            <a-card size="small" title="水厂" class="list-card" :body-style="{padding: 0}">
                <a slot="extra" @click="showWaterfactoryModal(1)">添加</a>
                <div v-if="waterfactorys.length > 0" v-for="(item,index) in waterfactorys" style="">
                   <div @click="handleWaterfactoryClick(item,index)" class="list-item-wrap" :class="waterfactoryIndex === index ? 'list-item-selected' : ''">
                       <div>
                           {{item.name}}
                       </div>
                       <div v-if="waterfactoryIndex === index" class="list-item-selected-icon">
                           <a-icon type="edit" @click="showWaterfactoryModal(2)"/>
                           <a-icon type="delete" @click="deleteWaterfactory(item)" style="margin-left: 10px;"/>
                       </div>
                   </div>
                    <div v-if="waterfactoryIndex === index" class="list-item-detail-wrap">
                       <span>经纬度:{{item.lon}},{{item.lat}}</span>
                    </div>
                </div>
                <div v-if="waterfactorys.length == 0" class="list-empty">
                   <a-empty></a-empty>
                </div>
            </a-card>
        </a-col>
        <a-col :md="6">
            <a-card size="small" title="组态点" class="list-card" :body-style="{padding: 0}">
                <a slot="extra" @click="showZutaiModal(1)">添加</a>
                <div v-if="zutais.length > 0" v-for="(item,index) in zutais" style="">
                    <div @click="handleZutaiClick(item,index)" class="list-item-wrap" :class="zutaiIndex === index ? 'list-item-selected' : ''">
                        <div>
                            {{item.name}}
                        </div>
                        <div v-if="zutaiIndex === index" class="list-item-selected-icon">
                            <a-icon type="edit" @click="showZutaiModal(2)"/>
                            <a-icon type="delete" @click="deleteZutaiConfirm(item)" style="margin-left: 10px;"/>
                        </div>
                    </div>
                    <div v-if="zutaiIndex === index" class="list-item-detail-wrap">
                        <span>经纬度:{{item.lon}},{{item.lat}}</span>
                    </div>
                </div>
                <div v-if="zutais.length == 0" class="list-empty">
                    <a-empty></a-empty>
                </div>
            </a-card>
        </a-col>
        <a-col :md="6">
            <a-card size="small" title="组态组件" class="list-card" :body-style="{padding: 0}">
                <a slot="extra" @click="showComponentModal(1)">添加</a>
                <div v-if="zutaiComponents.length > 0" v-for="(item,index) in zutaiComponents" style="">
                    <div @click="handleComponentClick(item,index)" class="list-item-wrap" :class="zutaiComponentIndex === index ? 'list-item-selected' : ''">
                        <div>
                            {{item.flagid}}
                        </div>
                        <div v-if="zutaiComponentIndex === index" class="list-item-selected-icon">
                            <a-icon type="edit" @click="showComponentModal(2)"/>
                            <a-icon type="delete" @click="deleteComponent(item)" style="margin-left: 10px;"/>
                        </div>
                    </div>
                    <div v-if="zutaiComponentIndex === index" class="list-item-detail-wrap">
                        <div>前端文本：{{item.descr}}</div>
                        <div>对应设备：{{item.idinmonitor == -1 ? '无' : item.deviceName}}</div>
                    </div>
                </div>
                <div v-if="zutaiComponents.length == 0" class="list-empty">
                    <a-empty></a-empty>
                </div>
            </a-card>
        </a-col>
        <waterfactory-modal ref="waterfacoryModal" @ok="handleWaterfactoryModalOk"></waterfactory-modal>
        <zutai-modal ref="zutaiModal" @ok="handleZutaiModalOk"></zutai-modal>
        <zutai-component-modal ref="componentModal" @ok="handleComponentModalOk"></zutai-component-modal>
    </a-row>
</template>

<script>
  import { monitorList,zutaiAll,zutaiComponentAll,deleteWaterfactoryt,deleteZutai,deleteZutaiComponent,getRegion,findRegionById,findRegionByPid,getAreaParam,devicePage,deviceExport,deviceTypeAll,zhibiaoAll,monitorAll,groupList,waterfactoryAll,findWaterfactoryByRegion } from "@/api/monitor/index";
  import '../monitor_pub.less'
  import moment from 'moment'
  import WaterfactoryModal from '@/views/monitor/component/WaterfactoryModal'
  import ZutaiComponentModal from '@/views/monitor/component/ZutaiComponentModal'
  import ZutaiModal from '@/views/monitor/component/ZutaiModal'
  export default {
    name: "MonitorPane",
    components:{
      WaterfactoryModal,
      ZutaiComponentModal,
      ZutaiModal
    },
    data(){
      return{
        waterfactorys: [],
        waterfactoryIndex: -1,
        zutais: [],
        zutaiIndex: -1,
        zutaiComponents:[],
        zutaiComponentIndex: -1,
        monitors: []
      }
    },
    created(){
     this.fetchWaterfactoryList()
    },
    methods:{
      fetchWaterfactoryList(){
        let param = getAreaParam()
        findWaterfactoryByRegion(param).then(r=>{
          const list = r.data
          this.waterfactorys.splice(0)
          for (let i = 0; i < list.length; i++) {
            list[i].selected = false
            this.waterfactorys.push(list[i])
          }
        })
      },
      handleWaterfactoryClick(item,index){
        console.log("item",item)
        if(this.waterfactoryIndex === index){
          return
        }
        for (let i = 0; i < this.waterfactorys.length; i++) {
          this.waterfactorys[i].selected = false
        }
        item.selected = true
        this.waterfactoryIndex = index
        this.fetchZutaiList()
        this.zutaiIndex = -1
        this.zutaiComponentIndex = -1
        this.zutaiComponents.splice(0)
        monitorList({wfid:this.waterfactorys[this.waterfactoryIndex].id}).then(r=>{
          this.monitors = r.data
          this.monitors.unshift({name:'无',id:-1})
        })
      },
      showWaterfactoryModal(type){
        if(type === 1){
          this.$refs.waterfacoryModal.add()
        }else{
          this.$refs.waterfacoryModal.edit(this.waterfactorys[this.waterfactoryIndex])
        }
      },
      handleWaterfactoryModalOk(values){
        this.fetchWaterfactoryList()
      },
      deleteWaterfactory(item){
        const self = this
        this.$confirm({
          title: '确认删除',
          content: `是否确认删除当前水厂？`,
          cancelText: '取消',
          okText: '确定',
          okType: 'danger',
          onOk () {
            return new Promise((resolve, reject) => {
              deleteWaterfactoryt({ id: item.id }).then(r => {
                if(r.code === 0){
                  self.$message.info('删除成功')
                  self.fetchWaterfactoryList()
                }else{
                  self.$message.warn(r.msg)
                }
              }).catch((e) => {
              }).finally(() => {
                resolve()
              })
            })
          }
        })
      },
      fetchZutaiList(){
        zutaiAll({wfid:this.waterfactorys[this.waterfactoryIndex].id} ).then(r => {
          const list = r.data
          this.zutais.splice(0)
          for (let i = 0; i < list.length; i++) {
            list[i].selected = false
            this.zutais.push(list[i])
          }
        });
      },
      handleZutaiClick(item,index){
        console.log("item",item)
        for (let i = 0; i < this.zutais.length; i++) {
          this.zutais[i].selected = false
        }
        item.selected = true
        this.zutaiIndex = index
        this.fetchComponentList()
      },
      showZutaiModal(type){
        if(this.waterfactoryIndex === -1){
          this.$message.info("请先选择一个水厂")
          return
        }
        if(type === 1){
          this.$refs.zutaiModal.add(this.waterfactorys[this.waterfactoryIndex].id)
        }else{
          this.$refs.zutaiModal.edit(this.zutais[this.zutaiIndex])
        }
      },
      handleZutaiModalOk(values){
        this.fetchZutaiList()
      },
      deleteZutaiConfirm(item){
        const self = this
        this.$confirm({
          title: '确认删除',
          content: `是否确认删除当前组态点？`,
          cancelText: '取消',
          okText: '确定',
          okType: 'danger',
          onOk () {
            return new Promise((resolve, reject) => {
              deleteZutai({ id: item.id }).then(r => {
                if(r.code === 0){
                  self.$message.info('删除成功')
                  self.fetchZutaiList()
                }else{
                  self.$message.warn(r.msg)
                }
              }).catch((e) => {
              }).finally(() => {
                resolve()
              })
            })
          }
        })
      },
      fetchComponentList(){
        zutaiComponentAll({ztid:this.zutais[this.zutaiIndex].id}).then(r => {
          const list = r.data
          this.zutaiComponents.splice(0)
          for (let i = 0; i < list.length; i++) {
            list[i].selected = false
            this.zutaiComponents.push(list[i])
          }
        });
      },
      handleComponentClick(item,index){
        console.log("item",item)
        for (let i = 0; i < this.zutaiComponents.length; i++) {
          this.zutaiComponents[i].selected = false
        }
        item.selected = true
        this.zutaiComponentIndex = index
      },
      showComponentModal(type){
        if(this.zutaiIndex === -1){
          this.$message.info("请先选择一个组态点")
          return
        }
        if(type === 1){
          this.$refs.componentModal.add(this.zutais[this.zutaiIndex].id,this.monitors)
        }else{
          this.$refs.componentModal.edit(this.zutaiComponents[this.zutaiComponentIndex],this.monitors)
        }
      },
      handleComponentModalOk(values){
        this.fetchComponentList()
      },
      deleteComponent(item){
        const self = this
        this.$confirm({
          title: '确认删除',
          content: `是否确认删除当前组态组件？`,
          cancelText: '取消',
          okText: '确定',
          okType: 'danger',
          onOk () {
            return new Promise((resolve, reject) => {
              deleteZutaiComponent({ id: item.id }).then(r => {
                if(r.code === 0){
                  self.$message.info('删除成功')
                  self.fetchComponentList()
                }else{
                  self.$message.warn(r.msg)
                }
              }).catch((e) => {
              }).finally(() => {
                resolve()
              })
            })
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>
