<template>
    <a-modal :title="isEdit ? '编辑' : '添加'" :width="640" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item label="类型名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入类型名称" v-decorator="['name', { rules: [{required: true, min: 2, message: '名称最少2个字符'}]}]" />
                </a-form-item>
                <a-form-item label="对应指标" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <!--<a-select placeholder="请选择对应指标" v-decorator="[ 'zhibiaos', {rules: [{ required: true, message: '请选择对应指标'}]} ]">-->
                        <!--<a-select-option v-for="item in typeOption" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>-->
                    <!--</a-select>-->
                    <a-select mode="multiple" placeholder="请选择对应指标" v-decorator="[ 'zhibiaos', {rules: [{ required: true, message: '请选择对应指标'}]} ]"  @change="handleChange">
                        <a-select-option v-for="item in zhibiaoList" :key="item.id + ''">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script>
  import { addDeviceType, updateDeviceType } from "@/api/monitor/index"
  import moment from 'moment'
  import pick from 'lodash.pick'
  export default {
    data() {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        },
        visible: false,
        confirmLoading: false,
        isEdit: false,
        id: null,
        record: null,
        zhibiaoList: [],
        form: this.$form.createForm(this),
        typeOption: [
          { id: 1, name: '人工巡测' },
          { id: 2, name: '管道计量' },
          { id: 3, name: '水位对应流量' }
        ],
      }
    },
    created() {

    },
    methods: {
      add(zhibiaoList) {
        this.zhibiaoList = zhibiaoList
        this.visible = true
        this.isEdit = false
        this.form.resetFields()
      },
      edit(record,zhibiaoList) {
        this.zhibiaoList = zhibiaoList
        this.id = record.id
        this.record = record
        this.visible = true
        this.isEdit = true
        console.log('edit');
        console.log(this.record);
        const { form: { setFieldsValue } } = this
        this.form.resetFields()
        this.$nextTick(() => {
          setFieldsValue(pick(record, ['name', 'createTime', 'zhibiaos']))
        })
      },
      handleChange(value) {
        if(value.indexOf('100') != -1){
          this.$nextTick(() => {
            this.form.setFieldsValue({ zhibiaos: ['100'] })
          })
        }
      },
      handleSubmit() {
        const { form: { validateFields } } = this
        this.confirmLoading = true
        validateFields((errors, values) => {
          if (!errors) {
            if (this.isEdit) {
              const param = {id:this.id,name:values.name,zhibiaos:values.zhibiaos}
              updateDeviceType(param).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.$emit('ok', values)
                }else{
                  this.$message.warn(r.msg)
                }
                this.visible = false
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            } else {
              addDeviceType(values).then(r => {
                values.isEdit = this.isEdit
                if(r.code === 0){
                  this.$emit('ok', values)
                }else{
                  this.$message.warn(r.msg)
                }
                this.visible = false
              }).catch(() => {}).finally(() => {
                this.confirmLoading = false
              })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>
